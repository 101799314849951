/**
 * 路由文件
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
  {
    path: "/",
    name: "platform",
    component: () => import("@/views/platform"),
    children: [
      {
        path: "/home", //计时计费
        name: "home",
        component: () => import("@/views/home"),
      },
      {
        path: "/tableManagement", //台桌管理
        name: "tableManagement",
        component: () => import("@/views/tableManagement"),
      },
      {
        path: "/billRecords", //消费记录
        name: "billRecords",
        component: () => import("@/views/billRecords"),
      },
      {
        path: "/vipMember", //vip管理
        name: "vipMember",
        component: () => import("@/views/vipMember"),
      },
      {
        path: "/recharge", //vip管理
        name: "recharge",
        component: () => import("@/views/recharge"),
      },
      {
        path: "/userManangment", //用户管理
        name: "userManangment",
        component: () => import("@/views/userManangment"),
      },
      {
        path: "/storeManagement", //商品管理
        name: "storeManagement",
        component: () => import("@/views/storeManagement"),
      },
      {
        path: "/revenueManagement", //营收分析
        name: "revenueManagement",
        component: () => import("@/views/revenueManagement"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// 路由导航守卫
router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem("token");

  if (to.path !== "/login") {
    if (token) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
