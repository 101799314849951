import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isLogin: false,
  },
  mutations: {
    changeLoginState(state, action) {
      state.isLogin = action;
    },
  },
});

export default store;
