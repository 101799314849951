import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from "@/store";
import JsonExcel from 'vue-json-excel'
import * as echarts from 'echarts';
import ElementUI from 'element-ui'
import VueAnimateNumber from 'vue-animate-number'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/index.scss'


Vue.use(ElementUI)
Vue.use(VueAnimateNumber)
Vue.component('downloadExcel', JsonExcel)
Vue.prototype.$echarts = echarts
Vue.use(store)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
